<template>
  <div :id="`${formName}-form-text-editor-itn`">
    <validation-observer :ref="`${formName}-text-editor-itn-observer`" v-slot="{ errors }">
      <b-overlay :show="disabled">
        <b-tabs :id="`${formName}-form-text-editor-itn-tabs`" align="left" class="mb-1">
          <template v-if="showLabel" #tabs-start>
            <li class="tabs-start nav-item align-self-end mr-2" role="presentation">
              <span class="label">{{ label }}<span v-if="required.length > 0" class="text-danger">&nbsp;* </span></span>
            </li>
          </template>
          <b-tab
            v-for="(lang, index) in languages"
            :id="`${formName}-form-text-editor-itn-tab-${lang.locale}`"
            :key="lang.locale"
            :active="lang.locale === localDefaultLang"
            class="tab"
            @click="focusOn(index)"
          >
            <template #title>
              <div class="title">
                <b-img :src="lang.img" class="img" />
                <span>{{ lang.locale }} </span>
<!--                <span v-if="isRequired(lang.locale)" class="text-danger">&nbsp;* </span>-->
              </div>
            </template>
            <validation-provider
              :name="label"
              :rules="getRules(lang.locale)"
              :vid="`${formName}-text-editor-itn-provider-${lang.locale}`"
            >
              <b-form-group>
                <quill-editor
                  :ref="`${formName}-form-text-editor-itn-input-${lang.locale}`"
                  :disabled="readonly"
                  :options="options"
                  :value="value ? value[lang.locale] : ''"
                  class="form-input"
                  @change="value[lang.locale] = $event.html"
                  @input="val => checkUrl(val)"
                />
                <small v-if="getFirstObserverError(errors) && !toolTipErrors" class="text-danger">
                  {{ getFirstObserverError(errors) }}
                </small>
                <b-tooltip
                  v-if="getFirstObserverError(errors) && toolTipErrors"
                  :target="`${formName}-form-text-editor-itn-input-${lang.locale}`"
                  placement="bottom"
                  show
                  variant="light"
                >
                  <small class="text-danger">{{ getFirstObserverError(errors) }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-tab>
          <template #empty>
            <div class="text-center text-muted">
              <b-form-input
                :id="`${formName}-form-text-editor-itn-input-empty`"
                :placeholder="localNoLanguages"
                class="form-input"
                disabled
              />
            </div>
          </template>
          <span v-if="urlNotice"><i>{{ $t('common.notice') }} {{ $t('common.url_notice') }}</i></span>
        </b-tabs>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import ImageCompress from 'quill-image-compress'

export default {
  name: 'AppFormTextEditorItn',

  components: {
    quillEditor,
  },

  props: {
    formName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Array,
      default: () => [],
    },
    defaultLang: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Array,
      default: () => [],
    },
    noLanguages: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    toolTipErrors: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      urlNotice: false,
    }
  },

  computed: {
    localNoLanguages() {
      return this.noLanguages || this.$t('common.no_languages')
    },
    localDefaultLang() {
      return this.defaultLang || this.$store.state.auth.defaultLang
    },
    options() {
      return {
        placeholder: this.label || this.$t('common.description'),
        theme: 'snow', // WARNING ! do not change this params
        modules: {
          toolbar: [
            'bold',
            'italic',
            'underline',
            'blockquote',
            // 'code-block',
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { direction: 'rtl' },
            { size: ['small', false, 'large', 'huge'] },
            { color: [] },
            { background: [] },
            { align: [] },
            'link',
            'image',
            'video',
          ],
          imageCompress: {
            quality: 0.7,
            maxWidth: 500,
            maxHeight: 500,
            imageType: 'image/jpeg',
            debug: false,
            suppressErrorLogging: false,
            insertIntoEditor: undefined,
          },
        },
      }
    },
  },

  beforeCreate() {
    Quill.register('modules/imageCompress', ImageCompress)
  },

  mounted() {
    Quill.register('modules/imageCompress', ImageCompress)
    // Ajout de la langue de la plateforme en required par défaut
    this.required.push(localStorage.getItem('appLang'))
  },

  methods: {
    focusOn(index) {
      this.$nextTick(() => {
        document.getElementsByClassName('ql-editor')[index].focus()
      })
    },

    // TODO: if focus on fr, but en is falsy, there is no error display who target this field when the form is submit
    isRequired(lang) {
      return this.required.includes(lang)
    },

    getRules(lang) {
      const rules = this.required.includes(lang) ? ['required'] : []
      if (this.rules) rules.push(this.rules)
      return rules.join('|')
    },

    getPlaceholder(lang) {
      let placeholder = this.label
      if (typeof this.placeholder === 'object') {
        placeholder = this.placeholder[lang]
      } else if (this.placeholder) {
        placeholder = this.placeholder
      }
      return placeholder
    },

    getFirstObserverError(errors) {
      const errorsToArray = Object.entries(errors)
        .map(key => ({ [key[0]]: key[1] }))
        .filter(error => Object.values(error)[0].length > 0)

      if (errorsToArray.length) {
        return `${Object.keys(errorsToArray[0]).toString().slice(-2).toUpperCase()} : ${Object.values(errorsToArray[0])}`
      }

      return null
    },
    checkUrl(val) {
      const urlOccurrences = val.match(/href=/g) ? val.match(/href=/g).length : 0
      const httpUrlOccurrences = val.match(/href=('|")https?:\/\//g) ? val.match(/href=('|")https?:\/\//g).length : 0
      if (urlOccurrences > httpUrlOccurrences) {
        this.urlNotice = true
      } else {
        this.urlNotice = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 0.2857rem;
  display: block !important;
  color: #5e5873;
  font-size: 0.857rem;
}

.tab {
  margin-bottom: 0px;
}

.form-input {
  margin-top: -14px;
}

.title {
  margin-bottom: -3px;
}

.img {
  margin-right: 10px;
  height: 14px;
  width: 22px;
}

.tabs-start {
  min-width: 100px;
}

.dark-layout {
  .label {
    color: $theme-dark-body-color;
  }
}

div.ql-tooltip.ql-editing.ql-flip {
  left: 0 !important;
}
</style>
